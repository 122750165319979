export const REQUIRED_STATES_COUNTRIES = [
  'CA',
  'US',
  'AE',
  'ES',
  'FR',
  'IN',
  'DE',
];

export const STATES_BY_COUNTRY = {
  DE: {
    BW: 'Baden-Württemberg',
    BY: 'Bavaria',
    BE: 'Berlin',
    BB: 'Brandenburg',
    HB: 'Bremen',
    HH: 'Hamburg',
    HE: 'Hesse',
    NI: 'Lower Saxony',
    MV: 'Mecklenburg-Vorpommern',
    NW: 'North Rhine-Westphalia',
    RP: 'Rhineland-Palatinate',
    SL: 'Saarland',
    SN: 'Saxony',
    ST: 'Saxony-Anhalt',
    SH: 'Schleswig-Holstein',
    TH: 'Thuringia',
  },
  AE: {
    AJ: 'Ajman',
    AZ: 'Abu Dhabi',
    DU: 'Dubai',
    FU: 'Fujairah',
    RK: "Imarat Ra's al Khaymah",
    SH: 'Sharjah',
    UQ: 'Imarat Umm al Qaywayn',
  },
  CA: {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NS: 'Nova Scotia',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  },
  ES: {
    CE: 'Ceuta',

    CN: 'Canary Islands',
    // for tax purposes Spain is either mainland (ES) or the listed provinces
    ES: 'N/A',
    ML: 'Melilla',
  },
  FR: {
    // for tax purposes France is either mainland (FR) or the listed provinces
    FR: 'N/A',
    GP: 'Guadeloupe',
    MQ: 'Martinique',
    RE: 'Réunion',
  },
  IN: {
    AN: 'Andaman and Nicobar',
    AP: 'Andhra Pradesh',
    AR: 'Arunachal Pradesh',
    AS: 'Assam',
    BR: 'Bihar',
    CH: 'Chandigarh',
    CT: 'Chhattisgarh',
    DH: 'Dadra and Nagar Haveli and Daman and Diu',
    DL: 'National Capital Territory of Delhi',
    GA: 'Goa',
    GJ: 'Gujarat',
    HP: 'Himachal Pradesh',
    HR: 'Haryana',
    JH: 'Jharkhand',
    JK: 'Jammu and Kashmir',
    KA: 'Karnataka',
    KL: 'Kerala',
    LA: 'Ladakh',
    LD: 'Lakshadweep',
    MH: 'Maharashtra',
    ML: 'Meghalaya',
    MN: 'Manipur',
    MP: 'Madhya Pradesh',
    MZ: 'Mizoram',
    NL: 'Nagaland',
    OR: 'Odisha',
    PB: 'Punjab',
    PY: 'Union Territory of Puducherry',
    RJ: 'Rajasthan',
    SK: 'Sikkim',
    TG: 'Telangana',
    TN: 'Tamil Nadu',
    TR: 'Tripura',
    UP: 'Uttar Pradesh',
    UT: 'Uttarakhand',
    WB: 'West Bengal',
  },
  US: {
    AK: 'Alaska',
    AL: 'Alabama',
    AR: 'Arkansas',
    AS: 'American Samoa',
    AZ: 'Arizona',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District Of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    FM: 'Federated States Of Micronesia',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    IA: 'Iowa',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    MA: 'Massachusetts',
    MD: 'Maryland',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MI: 'Michigan',
    MN: 'Minnesota',
    MO: 'Missouri',
    MP: 'Northern Mariana Islands',
    MS: 'Mississippi',
    MT: 'Montana',
    NC: 'North Carolina',
    ND: 'North Dakota',
    NE: 'Nebraska',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NV: 'Nevada',
    NY: 'New York',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    PW: 'Palau',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VA: 'Virginia',
    VI: 'Virgin Islands',
    VT: 'Vermont',
    WA: 'Washington',
    WI: 'Wisconsin',
    WV: 'West Virginia',
    WY: 'Wyoming',
  },
};

export const stateOptions = (countryCode: string) => {
  return Object.entries(
    STATES_BY_COUNTRY[countryCode as keyof typeof STATES_BY_COUNTRY],
  ).map(([value, label]) => ({
    label,
    value,
  }));
};
