import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { di } from 'react-magnetic-di';

import { useRouter } from 'next/router';

import { useOpportunityActions } from '@/hooks';
import { PrefillData, useServiceOpportunity } from '@/services/opportunity';
import { getQueryParams } from '@/utils';

export interface Props {
  onSuccess: () => void;
}

const InitialDataLoader = ({ onSuccess }: Props) => {
  di(
    getQueryParams,
    useFormContext,
    useOpportunityActions,
    useRouter,
    useServiceOpportunity,
  );

  const router = useRouter();
  const { t: token, o: opportunityId } = getQueryParams(router, 't', 'o');
  const { setValue: setOpportunity } = useOpportunityActions();
  const [{ data, promise, error }, { run: getOpportunityData }] =
    useServiceOpportunity();
  const { setValue } = useFormContext();

  useEffect(() => {
    setOpportunity({ token, opportunityId });
  }, [opportunityId, setOpportunity, token]);

  useEffect(() => {
    getOpportunityData(token, opportunityId);
  }, [getOpportunityData, opportunityId, token]);

  useEffect(() => {
    if (data) {
      (Object.keys(data) as Array<keyof PrefillData>).forEach(fieldName => {
        setValue(fieldName, data[fieldName], {
          shouldValidate: true,
        });
      });
      onSuccess();
    }
  }, [data, onSuccess, setValue]);

  if (error) {
    throw error;
  }

  if (!data && promise) {
    throw promise;
  }

  return null;
};

export default InitialDataLoader;
