import React, { ReactNode } from 'react';

import { spacer8, spacer32, Type } from '@spotify-internal/encore-web';

export interface Props {
  title: ReactNode;
  subtitle?: ReactNode;
}

const FormHeader = ({ title, subtitle }: Props) => (
  <>
    <Type as="h1" variant="alto" paddingBottom={subtitle ? spacer8 : spacer32}>
      {title}
    </Type>
    {subtitle && (
      <Type
        as="p"
        variant="ballad"
        semanticColor="textSubdued"
        paddingBottom={spacer32}
      >
        {subtitle}
      </Type>
    )}
  </>
);

export default FormHeader;
