import React, { ReactNode, useId, useState } from 'react';
import { di } from 'react-magnetic-di';

import { Tooltip, TooltipTrigger, Type } from '@spotify-internal/encore-web';
import { TypeProps } from '@spotify-internal/encore-web/types/src/core/components/Type';
import { OverlayDirections } from '@spotify-internal/encore-web/types/src/core/styles/mixins';

export type Props = TypeProps & {
  children: ReactNode;
  href: string;
  enabled: boolean;
  notification: ReactNode;
  placement?: OverlayDirections;
};

const ConditionalLink = ({
  children,
  enabled = false,
  href,
  notification,
  placement = TooltipTrigger.bottom,
  ...rest
}: Props) => {
  di(Tooltip, TooltipTrigger, Type, useState);

  const toolTipId = useId();
  const [show, toggleShow] = useState<boolean>(false);

  return (
    <TooltipTrigger
      as="span"
      overlay={
        show && (
          <Tooltip as="span" id={toolTipId}>
            {notification}
          </Tooltip>
        )
      }
      placement={placement}
      onShow={() => toggleShow(!enabled)}
      onHide={() => toggleShow(false)}
      withPortal
    >
      <Type
        as={enabled ? 'a' : 'span'}
        aria-labelledby={toolTipId}
        semanticColor="textBrightAccent"
        {...rest}
        {...(enabled
          ? { href }
          : {
              'aria-disabled': true,
              onClick: e => e.preventDefault(),
            })}
      >
        {children}
      </Type>
    </TooltipTrigger>
  );
};

export default ConditionalLink;
