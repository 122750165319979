import { ReactNode, useId } from 'react';
import { ScreenClass, useScreenClass } from 'react-grid-system';
import { di } from 'react-magnetic-di';

import { PopoverTrigger, Type } from '@spotify-internal/encore-web';

import { PopoverStyled } from './PlanCardItemPopover.styled';

interface Props {
  children: ReactNode[];
  isShown: boolean;
  onHide: () => void;
  onShow: () => void;
  triggerText: string;
}

const PlanCardItemPopover = ({
  children,
  isShown,
  onHide,
  onShow,
  triggerText,
}: Props) => {
  di(PopoverStyled, PopoverTrigger, Type, useScreenClass);
  const screenClass = useScreenClass();
  const id = useId();

  const isNarrow = (['xs', 'sm', 'md'] as ScreenClass[]).includes(screenClass);

  return (
    <PopoverTrigger
      as="span"
      overlay={
        isShown && (
          <PopoverStyled
            forwardedAs="span"
            id={id}
            colorSet="invertedDark"
            large={false}
            $isNarrow={isNarrow}
          >
            {children}
          </PopoverStyled>
        )
      }
      placement={isNarrow ? 'bottom' : 'bottomStart'}
      hideCloseButton
      onShow={onShow}
      onHide={onHide}
      onMouseOver={onShow}
      withPortal
    >
      <Type
        variant="balladBold"
        semanticColor={isShown ? 'textBrightAccent' : 'textSubdued'}
      >
        {triggerText}
      </Type>
    </PopoverTrigger>
  );
};

export default PlanCardItemPopover;
