import createUseValue from '@/utils/create-use-value';

interface Opportunity {
  token?: string;
  opportunityId?: string;
}

export const {
  useValue: useOpportunity,
  useValueActions: useOpportunityActions,
} = createUseValue<Opportunity>({}, 'opportunity');
