import { useCallback, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { di } from 'react-magnetic-di';

import {
  FormHelpText,
  IconWithText,
  spacer8,
  spacer16,
  spacer32,
  Type,
} from '@spotify-internal/encore-web';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { StripeElementChangeEvent } from '@stripe/stripe-js/types/stripe-js/elements/base';
import { StripeElementType } from '@stripe/stripe-js/types/stripe-js/elements-group';

import { useStripeValidationActions } from '@/hooks';

import PoweredByStripe from './PoweredByStripe';
import { CardElementContainer, StyledLabel } from './StripeForm.styled';

const elementOptions = {
  placeholder: '',
  style: {
    invalid: {
      color: '#000',
    },
  },
};

type ErrorFields = { [Property in StripeElementType]?: string } | null;

const StripeForm = () => {
  di(
    CardCvcElement,
    CardElementContainer,
    CardExpiryElement,
    CardNumberElement,
    Col,
    FormHelpText,
    IconWithText,
    PoweredByStripe,
    Row,
    StyledLabel,
    Type,
    useState,
    useStripeValidationActions,
  );

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const setLoaded = useCallback(() => setIsLoaded(true), []);

  const [errorFields, setErrorFields] = useState<ErrorFields>({});
  const { setValue: setStripeValidation } = useStripeValidationActions();

  const handleChange = useCallback(
    (event: StripeElementChangeEvent) => {
      const fields = errorFields ? { ...errorFields } : {};
      fields[event.elementType] = event.error ? event.error.message : '';

      if (!fields[event.elementType] && (event.empty || !event.complete)) {
        // Stripe does not validate an empty field, therefore remove it so
        // that it still triggers an invalid state.
        delete fields[event.elementType];
      }

      const fieldValues = Object.values(fields);
      setErrorFields(fields);
      setStripeValidation(
        fieldValues.length === 3 && fieldValues.every(value => !value),
      );
    },
    [errorFields, setStripeValidation],
  );

  return (
    <CardElementContainer $isLoaded={isLoaded} fluid>
      <Row style={{ marginBlockStart: spacer32 }}>
        <Col>
          <IconWithText
            align="end"
            icon={PoweredByStripe}
            style={{ paddingBottom: spacer16 }}
          >
            <Type
              as="h2"
              variant="celloCanon"
              style={{ marginInlineEnd: spacer16 }}
            >
              Payment method
            </Type>
          </IconWithText>
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledLabel htmlFor="card_number_element">
            <Type as="div" variant="violaBold" paddingBottom={spacer8}>
              Card number
            </Type>
            <CardNumberElement
              id="card_number_element"
              options={elementOptions}
              onChange={handleChange}
              onReady={setLoaded}
            />
            {errorFields?.cardNumber && (
              <FormHelpText error>{errorFields.cardNumber}</FormHelpText>
            )}
          </StyledLabel>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <StyledLabel htmlFor="card_expiry_element">
            <Type as="div" variant="violaBold" paddingBottom={spacer8}>
              Expiry date
            </Type>
            <CardExpiryElement
              id="card_expiry_element"
              options={elementOptions}
              onChange={handleChange}
            />
            {errorFields?.cardExpiry && (
              <FormHelpText error>{errorFields.cardExpiry}</FormHelpText>
            )}
          </StyledLabel>
        </Col>
        <Col xs={12} sm={6}>
          <StyledLabel htmlFor="card_cvc_element">
            <Type as="div" variant="violaBold" paddingBottom={spacer8}>
              CVC
            </Type>
            <CardCvcElement
              id="card_cvc_element"
              options={elementOptions}
              onChange={handleChange}
            />
            {errorFields?.cardCvc && (
              <FormHelpText error>{errorFields.cardCvc}</FormHelpText>
            )}
          </StyledLabel>
        </Col>
      </Row>
    </CardElementContainer>
  );
};

export default StripeForm;
