import { memo, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { di } from 'react-magnetic-di';

import { pink115, spacer32, Type, white } from '@spotify-internal/encore-web';
import Image from 'next/image';
import PlanSvg from 'public/plan.svg';

import { SmallChip } from '@/components/SmallChip';
import { getPlanRates } from '@/helpers/planRates';

import {
  Hr,
  IconContainer,
  NoWrap,
  Total,
  TypeListItemStyled,
  TypeListStyled,
} from './PlanCard.styled';
import PlanCardItemPopover from './PlanCardItemPopover';

const triggerCss = {
  color: '#AFB1FF',
  whiteSpace: 'nowrap',
};

const PlanCardContents = memo(() => {
  di(
    Hr,
    IconContainer,
    Image,
    NoWrap,
    PlanCardItemPopover,
    SmallChip,
    Total,
    Type,
    TypeListItemStyled,
    TypeListStyled,
    useFormContext,
    useState,
  );

  const { watch } = useFormContext();

  const selectedCountry = watch('billing_country_code');

  const [activePopover, setActivePopover] = useState<
    'chartable' | 'spotify' | undefined
  >();
  const onShowChartable = useCallback(
    () =>
      setActivePopover(activePopover === 'chartable' ? undefined : 'chartable'),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- re-creating callback breaks hover behavior
    [],
  );
  const onShowPodsights = useCallback(
    () => setActivePopover(activePopover === 'spotify' ? undefined : 'spotify'),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- re-creating callback breaks hover behavior
    [],
  );
  const onHide = useCallback(() => setActivePopover(undefined), []);

  const planRates = getPlanRates(selectedCountry);

  return (
    <>
      <IconContainer>
        <Image src={PlanSvg} layout="fixed" height="64px" width="65.52px" />
      </IconContainer>
      <Type as="h1" variant="canon" paddingBottom={spacer32}>
        With this plan, you get:
      </Type>
      <TypeListStyled>
        <TypeListItemStyled
          icon="chartable"
          isShown={activePopover === 'chartable'}
          variant="ballad"
          semanticColor="textSubdued"
        >
          Free{' '}
          <PlanCardItemPopover
            triggerText="Chartable Pro"
            isShown={activePopover === 'chartable'}
            onShow={onShowChartable}
            onHide={onHide}
          >
            Chartable helps you take audience development to the next level with
            audience insights and promotion attribution tools.{' '}
            <Type
              as="a"
              css={triggerCss}
              href="https://chartable.com/publishers/pricing"
              target="_blank"
            >
              Learn more ↗
            </Type>
          </PlanCardItemPopover>{' '}
          <NoWrap>
            plan for new Chartable users{' '}
            <SmallChip backgroundColor={pink115} color={white}>
              New
            </SmallChip>
          </NoWrap>
        </TypeListItemStyled>
        <TypeListItemStyled
          icon="spotify"
          isShown={activePopover === 'spotify'}
          variant="ballad"
          semanticColor="textSubdued"
        >
          Free{' '}
          <PlanCardItemPopover
            triggerText="Spotify Ad Analytics"
            isShown={activePopover === 'spotify'}
            onShow={onShowPodsights}
            onHide={onHide}
          >
            Spotify Ad Analytics helps you measure and drive advertising. With
            Megaphone, you can get access to Spotify Ad Analytics and your first
            4 million impressions free.{' '}
            <Type
              as="a"
              css={triggerCss}
              href="https://podsights.com/pricing"
              target="_blank"
            >
              Learn more ↗
            </Type>
          </PlanCardItemPopover>{' '}
          <NoWrap>
            <SmallChip backgroundColor={pink115} color={white}>
              New
            </SmallChip>
            <br /> with exclusive features for Megaphone users
          </NoWrap>
        </TypeListItemStyled>
        <TypeListItemStyled variant="ballad" semanticColor="textSubdued">
          Hosting & distribution capabilities
        </TypeListItemStyled>
        <TypeListItemStyled variant="ballad" semanticColor="textSubdued">
          Robust campaign management toolkit
        </TypeListItemStyled>
        <TypeListItemStyled variant="ballad" semanticColor="textSubdued">
          Detailed reporting & analytics
        </TypeListItemStyled>
        <TypeListItemStyled variant="ballad" semanticColor="textSubdued">
          Monetization tools via the Spotify Audience Network
        </TypeListItemStyled>
        <TypeListItemStyled variant="ballad" semanticColor="textSubdued">
          Live chat support, 4am-9pm M-F (ET)
        </TypeListItemStyled>
        <TypeListItemStyled variant="ballad" semanticColor="textSubdued">
          Unlimited users with role-based permissions
        </TypeListItemStyled>
        <TypeListItemStyled variant="ballad" semanticColor="textSubdued">
          {`49,500 downloads & overage $${planRates.cpm.toFixed(2)} CPM`}
        </TypeListItemStyled>
      </TypeListStyled>
      <Hr />
      <Total>
        <Type variant="canon">Total</Type>
        <div>
          <Type variant="canon">{`$${planRates.subscription}/month`}</Type>
          <Type as="p" variant="ballad" style={{ lineHeight: 1 }}>
            for 12 months
          </Type>
        </div>
      </Total>
    </>
  );
});

export default PlanCardContents;
