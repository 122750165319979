export const getPlanRates = (country: string) => {
  switch (country) {
    case 'DE':
      return {
        subscription: 99,
        cpm: 0.6,
      };
    case 'ES':
    case 'GB':
    case 'FR':
    case 'SE':
    case 'IT':
      return {
        subscription: 50,
        cpm: 1,
      };
    default:
    case 'US':
      return {
        subscription: 99,
        cpm: 2,
      };
  }
};
