import {
  gray85,
  gray95,
  spacer8,
  spacer24,
  spacer32,
  spacer48,
  TypeList,
  TypeListItem,
} from '@spotify-internal/encore-web';
import ChartableProSvg from 'public/chartable-pro.svg';
import ChartableProMonoSvg from 'public/chartable-pro-mono.svg';
import CheckSvg from 'public/check.svg';
import Spotify from 'public/spotify.svg';
import SpotifyMono from 'public/spotify-mono.svg';
import styled from 'styled-components';

import { breakpoints } from '@/gridConfiguration';
import { boxShadow } from '@/styles';

export const Container = styled.div`
  border-top: 1px solid ${gray85};
  padding: ${spacer48} 0 0;

  @media screen and (min-width: ${breakpoints.md}) {
    background-color: ${gray95};
    border-radius: ${spacer8};
    box-shadow: ${boxShadow};
    padding: ${spacer24} ${spacer32} ${spacer48};
    margin-bottom: ${spacer48};
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    margin-bottom: 0;
  }
`;

export const IconContainer = styled.div`
  margin: 0 0 16.53px -2px; // Adjust to the SVG
`;

export const TypeListStyled = styled(TypeList)`
  padding-left: 0;
  margin-bottom: ${spacer32};
`;

type listItem = 'check' | 'podsights' | 'chartable' | 'spotify';

const getIcon = (icon: listItem = 'check', suffix?: string): string => {
  const name = suffix ? `${icon}-${suffix}` : icon;
  switch (name) {
    case 'chartable':
      return ChartableProSvg.src;
    case 'spotify':
      return Spotify.src;
    case 'chartable-mono':
      return ChartableProMonoSvg.src;
    case 'spotify-mono':
      return SpotifyMono.src;
    default:
      return CheckSvg.src;
  }
};

export const TypeListItemStyled = styled(TypeListItem)<{
  icon?: listItem;
  isShown?: Boolean;
}>`
  background: url(${({ icon, isShown }) =>
      isShown && icon ? getIcon(icon) : getIcon(icon, 'mono')})
    no-repeat left top;
  background-size: ${spacer24};
  list-style: none;
  margin-bottom: 10px; // odd value not divisible by 4
  padding: 0;
  padding-inline-start: ${spacer32};

  &:last-child {
    margin-bottom: 0;
  }

  // preload the icons
  &:after {
    height: 0;
    overflow: hidden;
    position: absolute;
    width: 0;
    z-index: -1;
    content: url(${getIcon('chartable')}) url(${getIcon('podsights')});
  }
`;

export const NoWrap = styled.span`
  white-space: nowrap;
`;

export const Hr = styled.hr`
  margin: ${spacer32} 0 28px;
`;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  margin-block-end: 22px;
`;
