import React, { FormEvent } from 'react';
import { di } from 'react-magnetic-di';

import { useScreen } from '@/hooks';

import { Billing } from './Billing';
import { Complete } from './Complete';
import { Welcome } from './Welcome';

const onSubmitNoop = (e: FormEvent) => e.preventDefault();

const Form = () => {
  di(Billing, Complete, Welcome, useScreen);

  const [screen] = useScreen();

  return (
    <form onSubmit={onSubmitNoop}>
      {screen === 'welcome' && <Welcome />}
      {screen === 'billing' && <Billing />}
      {screen === 'complete' && <Complete />}
    </form>
  );
};

export default Form;
