import {
  Action,
  createActionsHook,
  createHook,
  createStore,
} from 'react-sweet-state';

const storeNameSet = new Set<string>();

const createUseValue = <T>(defaultValue: T, uniqueStoreName: string) => {
  type State = {
    value: T;
  };

  const storeName = `useValue:${uniqueStoreName}`;

  if (storeNameSet.has(storeName)) {
    throw new Error(
      `Duplicate store name "${storeName}" found in the set of store names.`,
    );
  }
  storeNameSet.add(storeName);

  const actions = {
    setValue:
      (value: State['value']): Action<State> =>
      ({ setState }) =>
        setState({
          value,
        }),
  };

  const store = createStore<State, typeof actions>({
    initialState: {
      value: defaultValue,
    },
    actions,
    name: storeName,
  });

  const useValue = createHook(store, {
    selector: (state: State) => state.value,
  });
  const useValueActions = createActionsHook(store);
  return { useValue, useValueActions };
};

export default createUseValue;
