import { SubmitHandler, useFormContext, useWatch } from 'react-hook-form';
import { di } from 'react-magnetic-di';

import { ButtonPrimary, LoadingIndicator } from '@spotify-internal/encore-web';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import type { FormData } from '@/data';
import { useOpportunity, useScreenActions, useStripeValidation } from '@/hooks';
import { useServiceRegisterActions } from '@/services/register';
import { useServiceStripeActions } from '@/services/stripe';
import { CustomError } from '@/utils';

import { LoadingIndicatorWrapper } from './SubmitButton.styled';

const SubmitButton = () => {
  di(
    ButtonPrimary,
    LoadingIndicator,
    LoadingIndicatorWrapper,
    useElements,
    useFormContext,
    useOpportunity,
    useScreenActions,
    useServiceRegisterActions,
    useServiceStripeActions,
    useStripe,
    useStripeValidation,
    useWatch,
  );

  const {
    handleSubmit,
    setError,
    setFocus,
    formState: { isSubmitting, isValid },
  } = useFormContext<FormData>();

  const [isStripeValid] = useStripeValidation();
  const isConsentChecked = useWatch({ name: 'billing_consent' });
  const isSubmitDisabled =
    !isValid || !isConsentChecked || isSubmitting || !isStripeValid;

  const [{ token, opportunityId }] = useOpportunity();
  const stripe = useStripe();
  const elements = useElements();
  const { setValue: setScreen } = useScreenActions();

  const { run: register } = useServiceRegisterActions();
  const { run: getPaymentMethod } = useServiceStripeActions();

  const onSubmit: SubmitHandler<FormData> = async data => {
    try {
      const paymentMethod = await getPaymentMethod(stripe!, elements!);
      await register(
        { ...data, payment_method: paymentMethod },
        { token: token!, opportunityId: opportunityId! },
      );
      setScreen('complete');
    } catch (error) {
      if (error instanceof Error) {
        const err = error as CustomError;
        if (
          ['first_name', 'last_name', 'country', 'email'].includes(
            err.field as keyof FormData,
          )
        ) {
          setScreen('welcome');
        }

        if ((error as CustomError).field) {
          // Must try/catch here because the field may not have rendered,
          // or not exist.
          try {
            setError(err.field as keyof FormData, {
              type: 'manual',
              message: error.message,
            });
            setFocus(err.field as keyof FormData);
          } catch (e) {
            // ignore
          }
        }
      }
    }
  };

  return (
    <>
      <ButtonPrimary
        disabled={isSubmitDisabled}
        fullWidth
        type="submit"
        onClick={handleSubmit(onSubmit)}
      >
        Complete purchase
      </ButtonPrimary>
      {isSubmitting && (
        <LoadingIndicatorWrapper>
          <LoadingIndicator indicatorSize={LoadingIndicator.sm} />
        </LoadingIndicatorWrapper>
      )}
    </>
  );
};

export default SubmitButton;
