import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { di } from 'react-magnetic-di';

import { FormGroup, spacer16, Type } from '@spotify-internal/encore-web';

import { ConditionalLink } from '@/components/ConditionalLink';
import { PlanCard } from '@/components/PlanCard';
import { FormData } from '@/data';
import { useFormStep } from '@/hooks/form-step';
import { useOpportunity } from '@/hooks/opportunity';

import { FormCheckboxStyled } from './Card.styled';
import { SubmitButton } from './SubmitButton';
import { createOrderFormUrl } from './utils';

const Card = () => {
  di(
    ConditionalLink,
    FormCheckboxStyled,
    FormGroup,
    PlanCard,
    SubmitButton,
    Type,
    createOrderFormUrl,
    useEffect,
    useFormContext,
    useFormStep,
    useOpportunity,
    useState,
  );

  const [{ token, opportunityId }] = useOpportunity();
  const { getValues, setValue, watch } = useFormContext();
  const { register } = useFormStep({
    fields: ['billing_consent'],
  });

  const getFormUrl = useCallback(
    () => createOrderFormUrl(getValues() as FormData, token!, opportunityId!),
    [getValues, opportunityId, token],
  );

  const [orderFormUrl, setOrderFormUrl] = useState(getFormUrl);

  useEffect(() => {
    const subscription = watch((_, changed) => {
      if (changed.name !== 'billing_consent') {
        setValue('billing_consent', false);
        setOrderFormUrl(getFormUrl);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [getFormUrl, setValue, watch]);

  return (
    <PlanCard>
      <FormGroup>
        <FormCheckboxStyled
          id="form_billing_consent"
          {...register('billing_consent', {
            required: true,
          })}
        >
          <Type as="div" variant="viola" paddingBottom={spacer16}>
            I agree on behalf of Customer to the{' '}
            <ConditionalLink
              enabled={Boolean(orderFormUrl)}
              href={orderFormUrl}
              notification="Please complete the form to generate your order."
              role="link"
              target="_blank"
            >
              Megaphone Platform Order Form
            </ConditionalLink>{' '}
            and{' '}
            <Type
              as="a"
              semanticColor="textBrightAccent"
              href="https://www.spotify.com/us/legal/megaphone-publisher-terms/"
              target="_blank"
            >
              Master Terms and Conditions
            </Type>{' '}
            and I have authority to bind Customer to this Agreement.
            <br />I also acknowledge and agree that the Customer's Megaphone
            Platform subscription automatically renews every 12 months, unless
            cancelled at least 30 days in advance in accordance with the Order
            Form.{' '}
          </Type>
        </FormCheckboxStyled>
      </FormGroup>
      <SubmitButton />
    </PlanCard>
  );
};

export default Card;
