import { Type } from '@spotify-internal/encore-web';
import styled from 'styled-components';

const defaultSize = '18px';

export type Props = {
  backgroundColor: string;
  color: string;
};

export const StyledChip = styled(Type)<Props>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${defaultSize};
  color: ${({ color }) => color};
  height: ${defaultSize};
  line-height: ${defaultSize};
  padding: 1px 6px 0;
`;
