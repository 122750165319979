import React from 'react';

import {
  IconEmail,
  IconWithText,
  spacer16,
  spacer24,
  Type,
} from '@spotify-internal/encore-web';
import Image from 'next/image';
import GreenCircleWithHeart from 'public/green-circle-with-heart.svg';

import { Card } from '@/components/Card';
import { Layout } from '@/components/Layout';
import { CenteredSingleColumn } from '@/components/Page';
import { supportUrl } from '@/constants';

const Complete = () => {
  return (
    <Layout title="Complete" hasLogo>
      <CenteredSingleColumn>
        <Card hasShadow style={{ textAlign: 'center' }}>
          <div style={{ marginBlockEnd: spacer24 }}>
            <Image
              src={GreenCircleWithHeart}
              height="113"
              width="176"
              aria-hidden
            />
          </div>
          <Type
            as="h1"
            variant="canon"
            semanticColor="textBase"
            paddingBottom={spacer24}
          >
            You're in!
          </Type>
          <Type as="p" variant="viola" paddingBottom={spacer24}>
            We've sent an email confirming your order and another email to
            create your Megaphone account. Now it’s time to explore our suite of
            publishing and monetization tools, invite your team to the platform,
            and get ready to maximize your podcast’s potential.
          </Type>

          <IconWithText
            icon={IconEmail}
            semanticColor="textBrightAccent"
            style={{ marginBlockEnd: spacer16 }}
          >
            <Type variant="viola">
              Please see your confirmation email for login details.
            </Type>
          </IconWithText>
          <Type
            as="a"
            href={supportUrl}
            target="_blank"
            style={{ display: 'block' }}
          >
            Need help?
          </Type>
        </Card>
      </CenteredSingleColumn>
    </Layout>
  );
};

export default Complete;
