import { useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import { Screen, useScreen } from '@/hooks/screen';
import { buildPathFromParams, getQueryParams } from '@/utils/url';

export const useScreenQuerySyncListener = () => {
  const router = useRouter();
  const qpScreen = getQueryParams(router, 'screen').screen as Screen;
  const [screen, { setValue: setScreen }] = useScreen();

  const isFirstRender = useRef<boolean>(true);
  const shouldListenToRouteChanges = useRef<boolean>(false);

  // listen to route changes
  useEffect(() => {
    // do not navigate away from the "complete" screen
    if (
      shouldListenToRouteChanges.current &&
      qpScreen !== screen &&
      screen !== 'complete'
    ) {
      setScreen(qpScreen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- subscribe to query param changes only
  }, [qpScreen]);

  // listen to screen changes
  useEffect(() => {
    const redirectParams = [
      buildPathFromParams(router, { screen }),
      undefined,
      {
        shallow: true,
      },
    ] as const;
    if (isFirstRender.current) {
      router.replace(...redirectParams);
      isFirstRender.current = false;
    } else {
      if (qpScreen !== screen) {
        router.push(...redirectParams);
        // allow listening ot route changes only after screen changes
        shouldListenToRouteChanges.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- subscribe to screen changes only
  }, [screen]);
};
