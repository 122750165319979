import { ReactNode } from 'react';
import { di } from 'react-magnetic-di';

import { Container } from './PlanCard.styled';
import PlanCardContents from './PlanCardContents';

export interface Props {
  children?: ReactNode;
}

const PlanCard = ({ children }: Props) => {
  di(Container, PlanCardContents);
  return (
    <Container>
      <PlanCardContents />
      {children}
    </Container>
  );
};

export default PlanCard;
