import { FieldValues } from 'react-hook-form';

import { BillingData, FormData, PDFData } from '@/data';
import { findCountryByKey } from '@/data/countries';
import { REQUIRED_STATES_COUNTRIES } from '@/helpers/states';
import { buildURLFromParams, checkAllTruthy, trimValue } from '@/utils';

export const fieldValuesToOrderFormValues = ({
  formData,
  token = '',
  opportunityId = '',
}: {
  formData: FieldValues;
  token?: string;
  opportunityId?: string;
}): PDFData => {
  const billingCountry = findCountryByKey(formData?.billing_country_code);

  return {
    billing_address: trimValue(
      formData?.billing_address_2,
      formData?.billing_address_1,
    ),
    billing_city: trimValue(formData?.billing_city),
    billing_contact: trimValue(
      formData?.billing_first_name,
      formData?.billing_last_name,
    ),
    billing_contact_title: '',
    billing_country: trimValue(billingCountry?.name),
    billing_email: trimValue(formData?.billing_email),
    billing_state: trimValue(formData?.billing_state),
    billing_zip: trimValue(formData?.billing_zip),
    oppId: opportunityId,
    organization_name: trimValue(formData?.organization_name),
    token,
  };
};

export const extractBillingData = (formData: FormData): BillingData => {
  return {
    billing_address_1: formData.billing_address_1,
    billing_address_2: formData.billing_address_2,
    billing_city: formData.billing_city,
    billing_consent: formData.billing_consent,
    billing_country_code: formData.billing_country_code,
    billing_email: formData.billing_email,
    billing_first_name: formData.billing_first_name,
    billing_last_name: formData.billing_last_name,
    billing_state: formData.billing_state,
    billing_zip: formData.billing_zip,
  };
};

const isBillingStateRequired = (countryCode: string) =>
  REQUIRED_STATES_COUNTRIES.includes(countryCode);

export const createOrderFormUrl = (
  formData: FormData,
  token: string,
  opportunityId: string,
): string => {
  const billingFields = extractBillingData(formData);

  if (
    !checkAllTruthy(
      billingFields,
      'billing_consent',
      'billing_address_2',
      !isBillingStateRequired(billingFields.billing_country_code)
        ? 'billing_state'
        : '',
    )
  ) {
    return '';
  }

  return buildURLFromParams({
    baseUrl: new URL(`${location.origin}/orderForm`),
    params: {
      ...fieldValuesToOrderFormValues({
        formData,
        token,
        opportunityId,
      }),
    },
  }).replace(location.origin, '');
};
