import { useCallback, useState } from 'react';

import { loadStripe, Stripe } from '@stripe/stripe-js';

const stripePublishableKey = process.env.NEXT_PUBLIC_PUBLISHABLE_KEY!;
const stripeInternationalPublishableKey =
  process.env.NEXT_PUBLIC_INTERNATIONAL_PUBLISHABLE_KEY!;

export const useLoadStripe = () => {
  const [stripeAccount, setStripeAccount] = useState<string>('US');

  const loadStripeAccount = useCallback(
    (publishableKey: string): Promise<Stripe | null> => {
      return loadStripe(publishableKey);
    },
    [],
  );

  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(
      loadStripeAccount(stripePublishableKey),
    );

  const onLoadStripeAccount = useCallback(
    (billingCountry: string) => {
      const key =
        billingCountry === 'US'
          ? stripePublishableKey
          : stripeInternationalPublishableKey;

      setStripeAccount(billingCountry);
      setStripePromise(loadStripeAccount(key || ''));
    },
    [loadStripeAccount, setStripePromise],
  );

  return {
    onLoadStripeAccount,
    stripeAccount,
    stripePromise,
  };
};
