import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';

import type { Key } from '@/data';

interface Args {
  fields: Key[];
  onNext?: () => void;
  onPrev?: () => void;
}

export const useFormStep = ({ fields, onNext, onPrev: onClickPrev }: Args) => {
  const {
    control,
    formState: { errors, isSubmitting },
    getValues,
    register,
    setFocus,
    setValue,
    trigger,
  } = useFormContext();

  const [shouldDisplayErrors, setShouldDisplayErrors] =
    useState<Boolean>(false);

  const firstError = useMemo(
    () => fields.find(field => Object.keys(errors).includes(field)),
    [errors, fields],
  );

  const onClickNext: MouseEventHandler = useCallback(
    async e => {
      e.preventDefault();
      const result = await trigger(fields);
      if (result && onNext) {
        onNext();
      } else {
        setShouldDisplayErrors(true);
      }
    },
    [fields, onNext, trigger],
  );

  // Set focus on a first field on step load
  const firstField = fields[0];
  useEffect(() => {
    setFocus(firstField);
  }, [firstField, setFocus]);

  // Set focus on a first error field
  useEffect(() => {
    if (shouldDisplayErrors && firstError) {
      setShouldDisplayErrors(false);
      setFocus(firstError);
    }
  }, [firstError, setFocus, shouldDisplayErrors]);

  return useMemo(
    () => ({
      control,
      errors,
      getValues,
      hasError: Boolean(firstError),
      isSubmitting,
      onClickNext: onNext ? onClickNext : onNext,
      onClickPrev,
      register,
      setValue,
    }),
    [
      control,
      errors,
      firstError,
      getValues,
      isSubmitting,
      onClickNext,
      onClickPrev,
      onNext,
      register,
      setValue,
    ],
  );
};
