import { FetchContactResponseData } from '@/data';
import { trimValue } from '@/utils';

import { PrefillData } from './types';

const opportunityToRequestData = ({
  email,
  firstName,
  lastName,
  organizationName,
}: Partial<FetchContactResponseData>): PrefillData => ({
  email: trimValue(email),
  first_name: trimValue(firstName),
  last_name: trimValue(lastName),
  organization_name: trimValue(organizationName),
});

export default opportunityToRequestData;
