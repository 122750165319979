import React, { Suspense, useCallback, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FormProvider, useForm } from 'react-hook-form';
import { di } from 'react-magnetic-di';

import { Form } from '@/components/Form';
import { InitialDataLoader } from '@/components/InitialDataLoader';
import { Loader } from '@/components/Page/Loader';
import { NotFound } from '@/components/Page/NotFound';
import { ScreenQuerySyncListener } from '@/components/ScreenQuerySyncListener';
import { defaultValues } from '@/data';

const IndexPage = () => {
  di(
    ErrorBoundary,
    Form,
    FormProvider,
    InitialDataLoader,
    Loader,
    NotFound,
    ScreenQuerySyncListener,
    useForm,
    useState,
  );

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const onSuccess = useCallback(() => {
    setIsDataLoaded(true);
  }, []);

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
  });

  return (
    <FormProvider {...methods}>
      <Suspense fallback={<Loader />}>
        {isDataLoaded ? (
          <>
            <Form />
            <ScreenQuerySyncListener />
          </>
        ) : (
          <ErrorBoundary FallbackComponent={NotFound}>
            <InitialDataLoader onSuccess={onSuccess} />
          </ErrorBoundary>
        )}
      </Suspense>
    </FormProvider>
  );
};

export default IndexPage;
