import { di } from 'react-magnetic-di';

import { Type } from '@spotify-internal/encore-web';
import { TypeProps } from '@spotify-internal/encore-web/types/src/core/components/Type';

import { Props as StyledProps, StyledChip } from './SmallChip.styled';

export type Props = TypeProps & StyledProps;

const SmallChip = (props: Props) => {
  di(StyledChip, Type);
  return <StyledChip forwardedAs={Type} variant="finale" {...props} />;
};

export default SmallChip;
