import { RequestData } from '@/data';
import { buildURLFromParams, createError, submissionError } from '@/utils';

import transformer from './transformer';

const opportunity = async (token: string, oppId: string) => {
  const url = buildURLFromParams({
    baseUrl: new URL(`${process.env.NEXT_PUBLIC_API_URL!}/contact/`),
    params: {
      token,
      oppId,
    },
  });
  const abortController = new AbortController();

  const timeoutId = setTimeout(() => abortController.abort(), 10000);

  try {
    const response = await fetch(url, {
      signal: abortController.signal,
    });
    if (!response.ok) {
      const text = await response.text();
      throw createError({
        error: new Error(text),
        name: submissionError.INVALID_OPPORTUNITY,
      });
    }
    const json: Partial<RequestData> = await response.json();
    return transformer(json);
  } finally {
    clearTimeout(timeoutId);
  }
};

export default opportunity;
