import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Controller } from 'react-hook-form';

import {
  ButtonPrimary,
  FormGroup,
  FormHelpText,
  FormInput,
} from '@spotify-internal/encore-web';
import Select from '@spotify-internal/react-select';

import { Card } from '@/components/Card';
import { FooterLink, FooterText } from '@/components/FooterText';
import { FormHeader } from '@/components/FormHeader';
import { Layout } from '@/components/Layout';
import { CenteredSingleColumn } from '@/components/Page';
import { TextAlignRight } from '@/components/TextAlignRight';
import { countryOptions } from '@/data/countries';
import { useScreenActions } from '@/hooks';
import { useFormStep } from '@/hooks/form-step';
import { emailPattern, unicodeNamePattern } from '@/utils/validation';

const Welcome = () => {
  const { setValue: setScreen } = useScreenActions();

  const { control, errors, hasError, onClickNext, register } = useFormStep({
    fields: ['first_name', 'last_name', 'country', 'email'],
    onNext: () => setScreen('billing'),
  });

  return (
    <Layout title="Welcome to Megaphone" hasLogo>
      <CenteredSingleColumn>
        <Card hasShadow>
          <Container fluid>
            <Row>
              <Col xs={12}>
                <FormHeader
                  title="Welcome to Megaphone"
                  subtitle="Getting started is easy, all you need is your email address."
                />
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup label="First name" labelFor="form_first_name">
                  <FormInput
                    aria-describedby="form_first_name_validation_message"
                    aria-required="true"
                    error={Boolean(errors.first_name)}
                    id="form_first_name"
                    type="text"
                    {...register('first_name', {
                      maxLength: {
                        value: 255,
                        message:
                          'First name must be less than 255 characters long.',
                      },
                      pattern: {
                        value: unicodeNamePattern,
                        message: 'Enter a valid first name.',
                      },
                      required: 'Required field.',
                    })}
                  />
                  {errors.first_name?.message && (
                    <FormHelpText id="form_first_name_validation_message" error>
                      <>{errors.first_name?.message}</>
                    </FormHelpText>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup label="Last name" labelFor="form_last_name">
                  <FormInput
                    aria-describedby="form_last_name_validation_message"
                    aria-required="true"
                    error={Boolean(errors.last_name)}
                    id="form_last_name"
                    type="text"
                    {...register('last_name', {
                      maxLength: {
                        value: 255,
                        message:
                          'Last name must be less than 255 characters long.',
                      },
                      pattern: {
                        value: unicodeNamePattern,
                        message: 'Enter a valid last name.',
                      },
                      required: 'Required field.',
                    })}
                  />
                  {errors.last_name?.message && (
                    <FormHelpText id="form_last_name_validation_message" error>
                      <>{errors.last_name?.message}</>
                    </FormHelpText>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup label="Country" labelFor="form_country">
                  <Controller
                    control={control}
                    name="country"
                    rules={{
                      required: 'Required field.',
                    }}
                    render={({ field: { onChange, ref, value, ...rest } }) => (
                      <Select
                        {...rest}
                        aria-required="true"
                        aria-describedby="form_country_validation_message"
                        id="form_country"
                        instanceId="form_country"
                        // @ts-ignore
                        inputRef={ref}
                        onChange={val => onChange(val?.value)}
                        options={countryOptions}
                        error={Boolean(errors.country)}
                        value={countryOptions.find(
                          option => option.value === value,
                        )}
                      />
                    )}
                  />
                  {errors.country?.message && (
                    <FormHelpText id="form_country_validation_message" error>
                      <>{errors.country?.message}</>
                    </FormHelpText>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup label="Email" labelFor="form_email">
                  <FormInput
                    aria-describedby="form_email_validation_message"
                    aria-required="true"
                    error={Boolean(errors.email)}
                    id="form_email"
                    type="email"
                    {...register('email', {
                      maxLength: {
                        value: 255,
                        message: 'Email must be less than 255 characters long.',
                      },
                      pattern: {
                        value: emailPattern,
                        message: 'Enter a valid email.',
                      },
                      required: 'Required field.',
                    })}
                  />
                  {errors.email?.message && (
                    <FormHelpText id="form_email_validation_message" error>
                      <>{errors.email?.message}</>
                    </FormHelpText>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <TextAlignRight>
                  <ButtonPrimary
                    onClick={onClickNext}
                    disabled={hasError}
                    type="submit"
                  >
                    Next
                  </ButtonPrimary>
                </TextAlignRight>
              </Col>
            </Row>
          </Container>
        </Card>
        <FooterText as="p">
          Already have an account?{' '}
          <FooterLink
            target="_blank"
            href="https://cms.megaphone.fm/users/sign_in"
          >
            Log in
          </FooterLink>
        </FooterText>
      </CenteredSingleColumn>
    </Layout>
  );
};

export default Welcome;
