import { memo } from 'react';

import Image from 'next/image';
import PoweredByStripeSvg from 'public/powered-by-stripe.svg';

const PoweredByStripe = memo(() => (
  <Image aria-hidden src={PoweredByStripeSvg} height="24" width="106" />
));

export default PoweredByStripe;
