import { FormCheckbox } from '@spotify-internal/encore-web';
import styled from 'styled-components';

// Hacking around encore web's FormCheckbox component
export const FormCheckboxStyled = styled(FormCheckbox)`
  // Text
  & + label {
    > span:last-child {
      padding-right: 0;
    }
  }
`;
