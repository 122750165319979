import {
  blueViolet75,
  blueViolet180,
  solidBlack0,
  solidWhite200,
  Type,
} from '@spotify-internal/encore-web';
import styled from 'styled-components';

import { breakpoints } from '@/gridConfiguration';

export const FooterText = styled(Type)`
  text-align: center;
  color: ${solidBlack0};

  @media screen and (min-width: ${breakpoints.md}) {
    color: ${solidWhite200};
    background-color: ${blueViolet75};
  }
`;

export const FooterLink = styled.a`
  @media screen and (min-width: ${breakpoints.md}) {
    color: ${blueViolet180};
    background-color: ${blueViolet75};
  }
`;
