import { useEffect } from 'react';
import { Col, Container, Row, Visible } from 'react-grid-system';
import { Controller } from 'react-hook-form';
import { di } from 'react-magnetic-di';

import {
  ButtonTertiary,
  FormGroup,
  FormHelpText,
  FormInput,
  spacer16,
  Type,
} from '@spotify-internal/encore-web';
import Select from '@spotify-internal/react-select';
import { Elements } from '@stripe/react-stripe-js';

import { FormHeader } from '@/components/FormHeader';
import { Layout } from '@/components/Layout';
import { TwoColumn } from '@/components/Page';
import { StripeForm } from '@/components/StripeForm';
import { countryOptions } from '@/data/countries';
import { REQUIRED_STATES_COUNTRIES, stateOptions } from '@/helpers/states';
import { useScreenActions } from '@/hooks';
import { useFormStep } from '@/hooks/form-step';
import { useLoadStripe } from '@/hooks/load-stripe';
import {
  emailPattern,
  unicodeNamePattern,
  unicodePattern,
} from '@/utils/validation';

import { Card } from './Card';

const Billing = () => {
  di(
    ButtonTertiary,
    Card,
    Col,
    Container,
    Controller,
    Elements,
    FormGroup,
    FormHeader,
    FormHelpText,
    FormInput,
    Layout,
    Row,
    Select,
    StripeForm,
    TwoColumn,
    Type,
    Visible,
    useEffect,
    useFormStep,
    useLoadStripe,
    useScreenActions,
  );

  const { setValue: setScreen } = useScreenActions();

  const { control, errors, getValues, onClickPrev, register, setValue } =
    useFormStep({
      // order dictates which field gets focus priority
      fields: [
        'billing_first_name',
        'billing_last_name',
        'organization_name',
        'billing_email',
        'billing_address_1',
        'billing_address_2',
        'billing_city',
        'billing_state',
        'billing_zip',
        'billing_country_code',
      ],
      onPrev: () => setScreen('welcome'),
    });

  const countryCode = getValues().billing_country_code;
  const isCountryUS = countryCode === 'US';
  const isCountryDE = countryCode === 'DE';

  const { onLoadStripeAccount, stripeAccount, stripePromise } = useLoadStripe();

  useEffect(() => {
    if (countryCode) {
      onLoadStripeAccount(countryCode);
    }
  }, [countryCode, onLoadStripeAccount]);

  return (
    <Elements stripe={stripePromise} key={stripeAccount}>
      <Layout title="Start your Professional Plan">
        <TwoColumn
          aside={
            <>
              <Card />
              <Visible md>
                <ButtonTertiary type="button" condensed onClick={onClickPrev}>
                  Back
                </ButtonTertiary>
              </Visible>
            </>
          }
        >
          <Container fluid>
            <Row>
              <Col xs={12}>
                <FormHeader title="Start your Professional Plan" />
              </Col>
              <Col xs={12}>
                <Type as="h2" variant="celloCanon" paddingBottom={spacer16}>
                  Billing information
                </Type>
              </Col>

              <Col xs={12} sm={6}>
                <FormGroup
                  label="First name"
                  labelFor="form_billing_first_name"
                >
                  <FormInput
                    aria-describedby="form_billing_first_name_validation_message"
                    aria-required="true"
                    error={Boolean(errors.billing_first_name)}
                    id="form_billing_first_name"
                    type="text"
                    {...register('billing_first_name', {
                      maxLength: {
                        value: 127,
                        message:
                          'First name must be less than 127 characters long.',
                      },
                      pattern: {
                        value: unicodeNamePattern,
                        message: 'Enter a valid first name.',
                      },
                      required: 'Required field.',
                    })}
                  />
                  {errors.billing_first_name?.message && (
                    <FormHelpText
                      id="form_billing_first_name_validation_message"
                      error
                    >
                      <>{errors.billing_first_name?.message}</>
                    </FormHelpText>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup label="Last name" labelFor="form_billing_last_name">
                  <FormInput
                    aria-describedby="form_billing_last_name_validation_message"
                    aria-required="true"
                    error={Boolean(errors.billing_last_name)}
                    id="form_billing_last_name"
                    type="text"
                    {...register('billing_last_name', {
                      maxLength: {
                        value: 127,
                        message:
                          'Last name must be less than 127 characters long.',
                      },
                      pattern: {
                        value: unicodeNamePattern,
                        message: 'Enter a valid last name.',
                      },
                      required: 'Required field.',
                    })}
                  />
                  {errors.billing_last_name?.message && (
                    <FormHelpText
                      id="form_billing_last_name_validation_message"
                      error
                    >
                      <>{errors.billing_last_name?.message}</>
                    </FormHelpText>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                  label="Customer Name"
                  labelFor="form_organization_name"
                >
                  <FormInput
                    aria-describedby="form_organization_name_validation_message"
                    aria-required="true"
                    id="form_organization_name"
                    type="text"
                    error={Boolean(errors.organization_name)}
                    {...register('organization_name', {
                      maxLength: {
                        value: 255,
                        message:
                          'Organization name must be less than 255 characters long.',
                      },
                      required: 'Required field.',
                    })}
                  />
                  {errors.organization_name?.message && (
                    <FormHelpText
                      id="form_organization_name_validation_message"
                      error
                    >
                      <>{errors.organization_name?.message}</>
                    </FormHelpText>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup label="Email address" labelFor="form_billing_email">
                  <FormInput
                    aria-describedby="form_billing_email_validation_message"
                    aria-required="true"
                    error={Boolean(errors.billing_email)}
                    id="form_billing_email"
                    type="email"
                    {...register('billing_email', {
                      maxLength: {
                        value: 255,
                        message:
                          'Email address must be less than 255 characters long.',
                      },
                      pattern: {
                        value: emailPattern,
                        message: 'Enter a valid email address.',
                      },
                      required: 'Required field.',
                    })}
                  />
                  {errors.billing_email?.message && (
                    <FormHelpText
                      id="form_billing_email_validation_message"
                      error
                    >
                      <>{errors.billing_email?.message}</>
                    </FormHelpText>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                  label="Billing address"
                  labelFor="form_billing_address_1"
                >
                  <FormInput
                    aria-describedby="form_billing_address_1_validation_message"
                    aria-required="true"
                    error={Boolean(errors.billing_address_1)}
                    id="form_billing_address_1"
                    type="text"
                    {...register('billing_address_1', {
                      maxLength: {
                        value: 255,
                        message:
                          'Billing address must be less than 255 characters long.',
                      },
                      pattern: {
                        value: unicodePattern,
                        message: 'Enter a valid billing address.',
                      },
                      required: 'Required field.',
                    })}
                  />
                  {errors.billing_address_1?.message && (
                    <FormHelpText
                      id="form_billing_address_1_validation_message"
                      error
                    >
                      <>{errors.billing_address_1?.message}</>
                    </FormHelpText>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                  label="Unit, Apt number"
                  labelFor="form_billing_address_2"
                  indicator="optional"
                >
                  <FormInput
                    aria-describedby="form_billing_address_2_validation_message"
                    id="form_billing_address_2"
                    type="text"
                    error={Boolean(errors.billing_address_2)}
                    {...register('billing_address_2', {
                      maxLength: {
                        value: 255,
                        message:
                          'Unit, Apt number must be less than 255 characters long.',
                      },
                      pattern: {
                        value: unicodePattern,
                        message: 'Enter a valid unit, apt number.',
                      },
                    })}
                  />
                  {errors.billing_address_2?.message && (
                    <FormHelpText
                      id="form_billing_address_2_validation_message"
                      error
                    >
                      <>{errors.billing_address_2?.message}</>
                    </FormHelpText>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup label="Country" labelFor="form_billing_country_code">
                  <Controller
                    control={control}
                    name="billing_country_code"
                    rules={{
                      required: 'Required field.',
                    }}
                    render={({ field: { onChange, ref, value, ...rest } }) => (
                      <Select
                        {...rest}
                        aria-required="true"
                        aria-describedby="form_billing_country_code_validation_message"
                        id="form_billing_country_code"
                        instanceId="form_billing_country_code"
                        // @ts-ignore
                        inputRef={ref}
                        onChange={val => {
                          onChange(val?.value);
                          setValue('billing_state', '', {
                            shouldValidate: true,
                          });
                        }}
                        options={countryOptions}
                        error={Boolean(errors.billing_country_code)}
                        value={countryOptions.find(
                          option => option.value === value,
                        )}
                      />
                    )}
                  />
                  {errors.billing_country_code?.message && (
                    <FormHelpText
                      id="form_billing_country_code_validation_message"
                      error
                    >
                      <>{errors.billing_country_code?.message}</>
                    </FormHelpText>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup label="City" labelFor="form_billing_city">
                  <FormInput
                    aria-describedby="form_billing_city_validation_message"
                    aria-required="true"
                    error={Boolean(errors.billing_city)}
                    id="form_billing_city"
                    type="text"
                    {...register('billing_city', {
                      maxLength: {
                        value: 255,
                        message: 'City must be less than 255 characters long.',
                      },
                      pattern: {
                        value: unicodeNamePattern,
                        message: 'Enter a valid city.',
                      },
                      required: 'Required field.',
                    })}
                  />
                  {errors.billing_city?.message && (
                    <FormHelpText
                      id="form_billing_city_validation_message"
                      error
                    >
                      <>{errors.billing_city?.message}</>
                    </FormHelpText>
                  )}
                </FormGroup>
              </Col>
              {REQUIRED_STATES_COUNTRIES.includes(countryCode) ? (
                <Col xs={12} sm={6}>
                  <FormGroup
                    label={isCountryUS || isCountryDE ? 'State' : 'Province'}
                    labelFor="form_billing_state"
                  >
                    <Controller
                      control={control}
                      name="billing_state"
                      rules={{
                        maxLength: {
                          value: 255,
                          message: `${
                            isCountryUS || isCountryDE ? 'State' : 'Province'
                          } must be less than 255 characters long.`,
                        },
                        pattern: {
                          value: unicodeNamePattern,
                          message: `Enter a valid ${
                            isCountryUS || isCountryDE ? 'state' : 'province'
                          }.`,
                        },
                        required: 'Required field.',
                      }}
                      render={({
                        field: { onChange, ref, value, ...rest },
                      }) => {
                        const commonProps = {
                          'aria-required': true,
                          'aria-describedby':
                            'form_billing_state_validation_message',
                          id: 'form_billing_state',
                          error: Boolean(errors.billing_state),
                        };
                        const options = stateOptions(countryCode);

                        return (
                          <Select
                            {...rest}
                            {...commonProps}
                            instanceId="form_billing_state"
                            // @ts-ignore
                            inputRef={ref}
                            onChange={val => onChange(val?.value)}
                            options={options}
                            value={options.find(
                              option => option.value === value,
                            )}
                          />
                        );
                      }}
                    />
                    {errors.billing_state?.message && (
                      <FormHelpText
                        id="form_billing_state_validation_message"
                        error
                      >
                        <>{errors.billing_state?.message}</>
                      </FormHelpText>
                    )}
                  </FormGroup>
                </Col>
              ) : null}
              <Col xs={12} sm={6}>
                <FormGroup
                  label={isCountryUS ? 'Zip code' : 'Postal Code'}
                  labelFor="form_billing_zip"
                >
                  <FormInput
                    aria-describedby="form_billing_zip_validation_message"
                    aria-required="true"
                    error={Boolean(errors.billing_zip)}
                    id="form_billing_zip"
                    type="text"
                    {...register(
                      'billing_zip',
                      isCountryUS
                        ? {
                            maxLength: {
                              value: 255,
                              message:
                                'Zip code must be less than 255 characters long.',
                            },
                            pattern: {
                              value: unicodePattern,
                              message: 'Enter a valid zip code.',
                            },
                            required: 'Required field.',
                          }
                        : {},
                    )}
                  />
                  {errors.billing_zip?.message && (
                    <FormHelpText
                      id="form_billing_zip_validation_message"
                      error
                    >
                      <>{errors.billing_zip?.message}</>
                    </FormHelpText>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <StripeForm />
              </Col>
              <Col xs={12}>
                <Visible lg xl xxl>
                  <ButtonTertiary type="button" condensed onClick={onClickPrev}>
                    Back
                  </ButtonTertiary>
                </Visible>
              </Col>
            </Row>
          </Container>
        </TwoColumn>
      </Layout>
    </Elements>
  );
};

export default Billing;
