import createUseService from '@/utils/create-use-service';

import service from './service';

export type { PrefillData } from './types';

export const { useService: useServiceOpportunity } = createUseService(
  service,
  'opportunity',
);
