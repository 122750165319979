import { Container } from 'react-grid-system';

import {
  black,
  red100,
  spacer16,
  spacer24,
} from '@spotify-internal/encore-web';
import styled from 'styled-components';

export const CardElementContainer = styled(Container)<{ $isLoaded: boolean }>`
  padding: 0 !important;
  opacity: ${({ $isLoaded }) => ($isLoaded ? 1 : 0)};
  transition: opacity 0.3s;

  .StripeElement {
    border-radius: 4px;
    border: 1px solid var(--essential-subdued, #878787);
    min-block-size: 48px;
    padding-left: ${spacer16};
    padding-top: 14px;
  }

  .StripeElement--focus {
    box-shadow: inset 0 0 0 1px ${black};
  }

  .StripeElement--invalid {
    border: 1px solid ${red100};
  }

  .StripeElement--invalid.StripeElement--focus {
    box-shadow: inset 0 0 0 1px ${red100};
  }
`;

export const StyledLabel = styled.label`
  display: block;
  padding-block-end: ${spacer24};
`;
