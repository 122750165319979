import { di } from 'react-magnetic-di';

import { useScreenQuerySyncListener } from '@/hooks';

const ScreenQuerySyncListener = () => {
  di(useScreenQuerySyncListener);

  useScreenQuerySyncListener();

  return null;
};

export default ScreenQuerySyncListener;
