import { Container, Row } from 'react-grid-system';
import { di } from 'react-magnetic-di';

import { LoadingIndicator } from '@spotify-internal/encore-web';

import { Layout } from '@/components/Layout';

const Loader = () => {
  di(Container, Layout, LoadingIndicator, Row);

  return (
    <Layout hasLogo>
      <Container fluid>
        <Row align="center" justify="center" style={{ height: '100vh' }}>
          <LoadingIndicator indicatorSize={LoadingIndicator.lg} />
        </Row>
      </Container>
    </Layout>
  );
};

export default Loader;
